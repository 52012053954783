import api from "@/api";
import { UmsMemberParam } from "@/models/team";
import { OptionsRow, SearchListRow } from "@/models/type";
import { parseTime } from "@/utils";
import { ElNotification } from "element-plus";
import { Vue, Prop } from "vue-property-decorator";

export default class OneVipCommon extends Vue {
  @Prop({ type: Number, default: 0 }) readonly id!:number

  public submitForm: UmsMemberParam = {

  }

  public payTypeList: OptionsRow[] = [
    { name: '微信支付', value: 2 },
    { name: '支付宝支付', value: 1 },
    { name: 'PayMe支付', value: 5 },
    { name: 'VISA支付', value: 3 },
    { name: 'Master支付', value: 4 }
  ]

  private commonList: Array<SearchListRow> = [
    { label: '用户编号', submitKey: 'memberId', type: 'span', },
    { label: '注册时间', submitKey: 'createTime', type: 'span', },
    { label: '账户状态', submitKey: 'status', type: 'span', },
    { label: '姓名', submitKey: 'username', type: 'input', },
    { label: '性别', submitKey: 'gender', type: 'select', options: [{name: '男', value: 1}, { name: '女', value: '2' }] },
    { label: '身份证号', submitKey: 'cardId', type: 'input', },
    { label: '手机号', submitKey: 'phone', type: 'input', },
    { label: '邮箱', submitKey: 'email', type: 'input', },
    { label: '微信', submitKey: 'wechat', type: 'span', },
    { label: 'Facebook', submitKey: 'facebook', type: 'span', },
    { label: 'Google', submitKey: 'google', type: 'span', },
    { label: 'Instagram', submitKey: 'instagram', type: 'span', },
    { label: '会员编号', submitKey: 'id', type: 'span', },
    { label: '会员属性', submitKey: 'attr', type: 'input', },
    { label: '所属机构', submitKey: 'institution', type: 'input', },
  ]

  private paySomeList: Array<SearchListRow> = [
    { label: '支付金额', submitKey: 'payMoney', type: 'span', },
    { label: '支付方式', submitKey: 'payTypeName', type: 'span' },
    { label: '有效日期', submitKey: 'limitTime', type: 'span', },
  ]


  getInfo(): void {
    api.teamGetMemberById(this.id).then(res => {
      const {member, social} = res.data
      member.createTime = parseTime(new Date(member.createTime).getTime())
      member.status = member.status ? '正常' : '异常'
      const obj = Object.assign({}, member)
      obj.payTypeName = this.payTypeList.find(item => item.value === obj.payType)?.name // 支付方式
      social.forEach(item => {
        obj[item.source] = item.account
      })
      this.submitForm = obj
    })
  }

  submit(): void {
    const obj = Object.assign({}, this.submitForm)
    for(const i in obj) {
      if(!obj[i]) delete obj[i]
    }
    delete obj.password
    obj.status = obj.status === '正常' ? 1 : 0
    api.teamEditMember(obj).then(res => {
      ElNotification({
        type: 'success',
        message: '保存成功'
      })
    })
  }

  render(): JSX.Element {
    let arr: Array<SearchListRow> = []
    if(this.submitForm.memberLevelId === 2) {
      arr = this.commonList.slice(0, this.commonList.length - 2)
    } else if(this.submitForm.memberLevelId === 3) {
      arr = this.commonList.slice(0, this.commonList.length - 2).concat(this.paySomeList)
    } else {
      arr = this.commonList
    }
    return <el-form
      model={this.submitForm}
      class="el-form-tao"
    >
      <el-row gutter={50}>
        {arr.map(item => <el-col
            lg={ 8 }
            sm={ 24 }
          >
            <el-form-item
              label={item.label}
            >
              {
                () => {switch (item.type) {
                  case 'input':
                    return <el-input
                      v-model={this.submitForm[item.submitKey as string]}
                      placeholder={ item.placeholder || '请输入' }
                      clearable
                    />
                  case 'select':
                    return <el-select v-model={ this.submitForm[item.submitKey as string] } placeholder={ item.placeholder || '请选择' } clearable style="width: 100%" filterable>
                      {item.options?.map((i:any) => (
                        <el-option
                          key={ i.value }
                          label={ i.name }
                          value={ i.value }
                        />
                      ))}
                    </el-select>
                  case 'time':
                    return <el-date-picker
                      v-model={ this.submitForm[item.submitKey as string] }
                      type="datetimerange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      clearable
                      style="width: 100%"
                    >
                    </el-date-picker>
                  case 'span':
                    return <span>{this.submitForm[item.submitKey as string]}</span>
                  default:
                    break;
                }}
              }
            </el-form-item>
          </el-col>
        )}
      </el-row>
      <div class="form-btn">
        <div onClick={this.submit}>保存</div>
      </div>
    </el-form>
  }
  mounted(): void {
    this.getInfo()
  }
}
