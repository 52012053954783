import Tabs from "@/components/Tabs";
import { TabListRow } from "@/models/type";
import { ElCard, ElPageHeader } from "element-plus";
import { Vue } from "vue-property-decorator";
import { h } from "vue";
import OneVipCommon from "./components/oneVipCommon";
import OneVipSign from "./components/oneVipSign";
import OneVipPay from "./components/oneVipPay";
import OneVip from ".";
import MoreVip from "../moreVip";

export default class OneVipDetail extends Vue {

  private activeName = 'common'
  private tabList: Array<TabListRow> = [
    { title: '基础信息', activeName: 'common' },
    { title: '报名记录', activeName: 'sign' },
    { title: '购买记录', activeName: 'pay' }
  ]

  activechange(activeName: string): void {
    this.activeName = activeName
  }

  detailDom(): JSX.Element {
    const __parent = this.$parent as OneVip
    const id = __parent.detailId

    const slots = {
      common: () => h(OneVipCommon, {
        id
      }),
      sign: () => h(OneVipSign, {
        id
      }),
      pay: () => h(OneVipPay, {
        id
      })
    }

    return h(Tabs, {
      tabList: this.tabList,
      activeName: this.activeName,
      onActivechange: this.activechange
    }, slots)
  }

  goBack(): void {
    const __parent = this.$parent as MoreVip
    __parent.type = ''
    this.$nextTick(() => {
      __parent.getList()
    })
  }

  backDom(): JSX.Element {
    return h(ElPageHeader, {
      title: '会员详情',
      onBack: this.goBack
    })
  }

  render(): JSX.Element {
    return h('div', {
      class: 'one_vip'
    }, [
      h(ElCard, {
        class: 'one_vip-detail',
      }, this.backDom),
      h(ElCard, {
      class: 'one_vip-detail',
    }, this.detailDom)])
  }
}
