import api from "@/api";
import Button from "@/components/Button";
import Card from "@/components/Card";
import Pagination from "@/components/Pagination";
import Search from "@/components/Search";
import Switch from "@/components/Switch";
import { UmsMemberParam } from "@/models/team";
import { ButtonListRow, OptionsRow, PaginationRow, SearchListRow, TableRow } from "@/models/type";
import { debounceClick, typeWatch } from "@/utils";
import { ElMessageBox, ElNotification } from "element-plus";
import { ElFormItemContext } from "element-plus/lib/el-form";
import { Vue, Watch } from "vue-property-decorator";
import OneVipDetail from "./detail";
import './index.scss'

export default class OneVip extends Vue {
  private title = ''
  private type = ''
  private changeType = ''
  private total = 0
  private detailBool = false
  private searchList: Array<SearchListRow> = []
  private tableData: Array<any> = []
  public memberLevelList: Array<OptionsRow> = []
  public detailId = 0

  public ids: Array<any> = []

  public switchNum = 0

  private tableList: Array<TableRow> = [
    { label: 'ID', prop: 'id' },

    { label: '会员类别', slot: {
      default: (scope: any) => <span>{this.memberLevelList.find(item => item.value === scope.row.memberLevelId)?.name}</span>
    } },
    { label: '姓名', prop: 'username' },
    { label: '性别', slot: {
      default: (scope: any) => <span>{scope.row.gender === 1 ? '男' : '女'}</span>
    } },
    { label: '手机号', prop: 'phone' },
    { label: '邮箱', prop: 'email' },
    { label: '身份证号', prop: 'cardId' },
    { label: '账户状态', slot: {
      default: (scope: any) => <Switch switchBool={ scope.row.status } bindValue={{ open: 1, close: 0 }} onSwitch={val => this.switchClick(val, scope.row.id)} />
    } },
    { label: '操作', slot: {
      default: (scope: any) => scope.row.loading
        ? <span class="el-icon-loading" />
        : <span class="span-button-primary" onClick={ () => this.vipDetail(scope.row.id) }>详情</span>
    } }
  ]
  private buttonList: Array<ButtonListRow> = [
    { title: '添加', icon: 'el-icon-plus', click: 'oneVipAdd', popover: true, popoverList: [
      { title: '添加免费会员', click: 'feeVipAdd' },
      { title: '添加自定义会员', click: 'customizeVipAdd' }
    ] },
    { title: '删除', icon: 'el-icon-minus', type: 'danger', click: 'oneVipDelete' },
  ]
  private tableDom(): JSX.Element {
    return <el-table
      data={ this.tableData }
      onSelectionChange = {this.selectionChange}
    >
      <el-table-column type="selection" />
      {this.tableList.map(item => (
        <el-table-column
          label={ item.label }
          align="center"
          prop={ item.prop }
          v-slots={ item.slot }
        />
      ))}
    </el-table>
  }
  public searchObj: any = {}

  private submitForm: UmsMemberParam = {
    attr: '',
    cardId: '',
    email: '',
    gender: 1,
    institution: '',
    memberLevelId: 0,
    phone: '',
    status: 1,
    username: ''
  }
  private formRules: any = {
    username: [
      {required: true, message: '请输入姓名'}
    ],
    phone: [
      {required: true, message: '请输入手机号'}
    ],
    email: [
      {required: true, message: '请输入邮箱'}
    ]
  }

  vipDetail(id): void {
    this.detailId = id
    this.type = 'detail'
  }

  switchClick(status, ids): void {
    api.teamSetMemberStatus(
      {status},
      [ids]
    ).then(() => {
      ElNotification({
        type: 'success',
        message: '操作成功'
      })
      this.switchNum++
    })
  }

  @Watch('switchNum')
  switchChange(val: number): void {
    if(val) {
      this.getList()
    }
  }

  @Watch('type')
  typeChange(val: string): void {
    this.title = typeWatch(val)
  }

  buttonClick(click: string): void {
    this[click]()
  }

  feeVipAdd(): void {
    this.type = 'add'
    this.changeType = 'fee'
    this.detailBool = true
  }

  customizeVipAdd(): void {
    this.type = 'add'
    this.changeType = 'customize'
    this.detailBool = true
  }

  oneVipAdd(): boolean {
    // 没用 但是要写 有点沙雕
    return false
  }
  oneVipDelete(): void {
    if(!(this.ids.length)) {
      ElNotification({
        type: 'warning',
        message: '请选择要删除的列表'
      })
      return
    }
    ElMessageBox.confirm('确定删除该列表？', '删除成功', {
      type: 'warning'
    }).then(() => {
      api.teamDeleteMember(this.ids).then(res => {
        ElNotification({
          title: '成功',
          message: '删除成功',
          type: 'success'
        })
        this.getList()
      })
    }).catch(() => false)
  }
  getList(): void {
    this.tableData = []
    const __pagination = this.$refs.pagination as any
    const { pageNum, pageSize } = __pagination.paginationObj as PaginationRow
    api.teamGetMemberList({ pageNum, pageSize, kind: 1, ...this.searchObj }).then(res => {
      this.tableData = res.data.list,
      this.total = res.data.total
    })
  }

  detailClose(): void {
    const __form = this.$refs.form as any
    __form.resetFields()
    this.detailBool = false
    this.changeType = ''
    this.type = ''
  }

  submit(): void {
    const __form = this.$refs.form as ElFormItemContext
    __form.validate(v => {
      if(v) {
        const obj = Object.assign({}, this.submitForm)
        obj.memberLevelId = this.changeType === 'fee' ? 2 : 1
        api.teamAddMember(obj).then(res => {
          ElNotification({
            title: '成功',
            message: '添加成功',
            type: 'success'
          })
          this.detailClose()
          this.getList()
        })
      } else {
        return false
      }
    })
  }

  selectionChange(row: any): void {
    this.ids = row.map(item => item.id)
  }

  oneVipDetailDom(): JSX.Element {
    const slots = {
      // 对话框底部元素
      footer: () => (
        <span class="dialog-footer">
          <button class="dialog-footer-btn dialog-footer-btn-l" onClick={ this.detailClose }>取 消</button>
          <button class="dialog-footer-btn dialog-footer-btn-r" onClick={ () => debounceClick(this.submit) }>确 定</button>
        </span>
      )
    }

    return <el-dialog
      title={`${this.title}${this.getTitle}`}
      v-model={ this.detailBool }
      width="30%"
      before-close={ this.detailClose }
      v-slots={ slots }
      custom-class="resource-dialog"
    >
      <el-form
        ref="form"
        model={ this.submitForm }
        rules={ this.formRules }
        label-width="120px"
        class="el-form-tao"
      >
        <el-form-item label="会员编号" prop="id">
          <el-input v-model={ this.submitForm.id } placeholder="会员编号系统自动生成" disabled clearable />
        </el-form-item>
        <el-form-item label="姓名" prop="username">
          <el-input v-model={ this.submitForm.username } placeholder="请输入姓名" clearable />
        </el-form-item>
        {this.changeType === 'customize' && <el-form-item label="会员属性" prop="attr">
          <el-input v-model={ this.submitForm.attr } placeholder="请输入会员属性" clearable />
        </el-form-item>}
        <el-form-item label="性别" prop="gender">
          <el-radio-group v-model={this.submitForm.gender}>
            <el-radio label={1}>男</el-radio>
            <el-radio label={2}>女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="身份证" prop="cardId">
          <el-input v-model={ this.submitForm.cardId } placeholder="请输入身份证" clearable />
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model={ this.submitForm.phone } placeholder="请输入手机号" clearable />
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model={ this.submitForm.email } placeholder="请输入邮箱" clearable />
        </el-form-item>
        {this.changeType === 'customize' && <el-form-item label="所属机构" prop="institution">
          <el-input v-model={ this.submitForm.institution } placeholder="请输入机构" clearable />
        </el-form-item>}
        <el-form-item label="注册时间" prop="createTime">
          <el-input v-model={ this.submitForm.createTime } placeholder="注册时间自动生成" disabled clearable />
        </el-form-item>
      </el-form>
    </el-dialog>
  }

  get getTitle(): string {
    switch(this.changeType) {
      case 'fee':
        return '免费会员'
      case 'customize':
        return 'DIY会员'
      default:
        return ''
    }
  }

  getData(): void {
    Promise.all([
      api.memberLevelList()
    ]).then(res => {
      this.memberLevelList = res[0].data.map(item => ({ name: item.name, value: item.id }))
      this.searchList = [
        { label: '会员编号', type: 'input', submitKey: 'memberId' },
        { label: '姓名', type: 'input', submitKey: 'name' },
        { label: '身份证号', type: 'input', submitKey: 'cardId' },
        { label: '手机号', type: 'input', submitKey: 'phone' },
        { label: '邮箱', type: 'input', submitKey: 'email' },
        { label: '会员级别', type: 'select', options: this.memberLevelList, submitKey: 'memberLevelId' },
      ]
    })
  }
  onSearch(obj: any): void {
    this.searchObj = Object.assign({}, obj)
    this.getList()
  }
  created(): void {
    this.getData()
  }

  render(): JSX.Element {
    return this.type === 'detail'
      ? <OneVipDetail />
      : <div class="one_vip">
        <Card title="个人会员查询" class="one_vip-card">
          <Search class="one_vip-search" list={ this.searchList } onSearch={ this.onSearch } />
        </Card>
        <Card title="会员信息">
          <div class="one_vip-search">
            <Button list={ this.buttonList } onClick={ this.buttonClick } onPopoverClick={ this.buttonClick }/>
            {this.tableDom()}
            <Pagination ref="pagination" total={ this.total } onSizeChange={ this.getList } onCurrentChange={ this.getList } />
          </div>
        </Card>
        {this.oneVipDetailDom()}
      </div>
  }
  mounted(): void {
    this.getList()
  }
}
