import api from "@/api";
import Pagination from "@/components/Pagination";
import { OptionsRow, PaginationRow, TableRow } from "@/models/type";
import { parseTime } from "@/utils";
import { Prop, Vue, Options } from "vue-property-decorator";

@Options({
  watch: {
    srcNum(num: number): void {
      if(num) {
        this.imgViewerShow()
      }
    },
    pushNum(num: number): void {
      if(num) {
        this.orderPush()
      }
    }
  }
})
export default class oneVipPay extends Vue {

  @Prop({ type: Number, default: 0 }) readonly id!:number

  private tableData: Array<any> = []
  private total = 0
  public pushId = 0
  srcNum = 0
  pushNum = 0

  public orderStatusList: OptionsRow [] = [
    { name: '待付款', value: 0 },
    { name: '待发货', value: 1 },
    { name: '已发货', value: 2 },
    { name: '已完成', value: 3 },
    { name: '已关闭', value: 4 },
    { name: '无效订单', value: 5 },
    { name: '已评价', value: 6 },
  ]

  public payTypeList: OptionsRow[] = [
    { name: '微信支付', value: 2 },
    { name: '支付宝支付', value: 1 },
    { name: 'PayMe支付', value: 5 },
    { name: 'VISA支付', value: 3 },
    { name: 'Master支付', value: 4 }
  ]

  public url = ''
  public srcList: Array<string> = []
  private tableList: Array<TableRow> = [
    { label: '序号', slot: {
      default: (scope: any) => <span>{scope.$index + 1}</span>
    } },
    { label: '订单编号', prop: 'orderSn' },
    { label: '商品首图', slot: {
      default: (scope: any) => <img class="mall-img pointer" src={ scope.row.goodsPic } alt={ scope.row.title } onClick={() => this.imgShow(scope.row.goodsPic)} />
    } },
    { label: '收货人', prop: 'receiverName' },
    { label: '定价/实付', slot: {
      default: (scope: any) => <span>{scope.row.totalAmount} / {scope.row.payAmount}</span>
    } },
    { label: '支付方式', prop: 'payType', slot: {
      default: (scope: any): JSX.Element => <span>{ this.payTypeList.find(item => item.value === scope.row.payType)?.name }</span>
    } },
    { label: '创建时间', slot: {
      default: (scope: any) => <span>{ parseTime(new Date(scope.row.createTime).getTime()) }</span>
    } },
    { label: '状态', slot: {
      default: (scope: any) => <span>{this.orderStatusList.find(item => item.value === scope.row.status)?.name}</span>
    } },
    { label: '操作', slot: {
      default: (scope: any): JSX.Element => <div>
        <span class="span-button-primary" onClick={() => this.see(scope.row.id)}>查看</span>
      </div>
    } }
  ]

  orderPush(): void {
    this.$router.push({
      name: 'MallOrder',
      query: {
        id: this.pushId,
        back: 'ClientOneVip'
      }
    })
  }

  imgShow(url: string): void {
    this.url = url
    this.srcList = this.tableData.map(item => item.goodsPic) // 将类型为图片的给srcList
    this.srcNum++
  }

  see(id: number): void {
    this.pushId = id
    this.pushNum++
  }


  /**
   * @description 图片dom
   * @author Jinx
   * @date 2021-10-19 19:53:21
   * @returns {*}  {JSX.Element}
   * @memberof Commodity
   */
   imgViewerDom(): JSX.Element {
    return <el-image
      ref="img"
      style="width: 0; height: 0; z-index: 54188;"
      src={ this.url }
      preview-src-list={ this.srcList }
    >
    </el-image>
  }

  /**
   * @description 图片展示
   * @author Jinx
   * @date 2021-08-19 14:27:43
   * @memberof FirstHome
   */
   imgViewerShow(): void {
    const __img =  this.$refs.img as any
    __img.showViewer = true
  }

  private getList(): void {
    const __pagination = this.$refs.pagination as any
    const { pageNum, pageSize }  = __pagination.paginationObj as PaginationRow


    api.orderList({ pageNum, pageSize, memberId: this.id }).then(res => {
      this.tableData = res.data.list
      this.total = res.data.total
    })
  }

  private tableDom(): JSX.Element {
    return <el-table
      data={ this.tableData }
    >
      {this.tableList.map(item => (
        <el-table-column
          label={ item.label }
          prop={ item.prop }
          align="center"
          v-slots={ item.slot }
        />
      ))}
    </el-table>
  }

  render(): JSX.Element {
    return <div>
      {this.tableDom()}
      <Pagination total={ this.total } ref="pagination" onCurrentChange={ this.getList } onSizeChange={ this.getList } />
      {this.imgViewerDom()}
    </div>
  }
  mounted(): void {
    this.getList()
  }
}
